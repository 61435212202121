<template>
  <template v-if="!error">
    <div :class="wrapperClasses">
      <template v-for="(entry, index) in entries" :key="index">
        <Brand
          v-if="index < listArgs.limit || showEntries"
          :id="`list-entry-` + index"
          :block-data="blockData"
          :entry="entry"
        />
      </template>
    </div>
    <div
      v-if="entryCount > listArgs.limit"
      class="flex flex-row justify-center mx-auto text-sm text-center mt-md text-vdv-base md:-mx-sm"
    >
      <div
        :id="showMoreBtnId"
        class="flex flex-row items-center cursor-pointer"
        @click="toggleEntries"
      >
        <span class="mr-2xs">{{
          showEntries ? t('list.showLess') : t('list.showMore')
        }}</span>
        <div v-show="!showEntries && !isReloading">
          <FaIcon icon-class="far fa-chevron-down" classes="fa-fw text-xs" />
        </div>
        <div v-show="showEntries && !isReloading">
          <FaIcon icon-class="far fa-chevron-up" classes="fa-fw text-xs" />
        </div>
        <span v-if="isReloading">
          <FaIcon icon-class="fas fa-circle-notch fa-spin mx-2xs" />
        </span>
      </div>
    </div>
  </template>
  <template v-else><LoadingError /></template>
</template>

<script setup lang="ts">
import { scrollToElement } from '@/helpers/scroll';
import Brand from './brand.vue';
import { useCachedPost } from '~~/src/composables/dataFetching/genericFetchers.js';
import { ESectionHandle } from '~~/src/@types/section-handle';
import type { BrandsData } from '../../blockType';
import FaIcon from '@/components/fa-icon.vue';
import { LoadingError } from '@/complib';
import { handleLoadingError } from '~/utils/handleLoadingError';

const { t } = useTrans();

const props = defineProps({
  blockData: {
    type: Object as PropType<BrandsData>,
    required: true,
    default: {} as BrandsData,
  },
  wrapperClasses: {
    type: String,
    required: false,
    default: '',
  },
});

const error = ref(false);

type ExtendableListArgs = {
  id: string[];
  sectionId: string[];
  limit: number | null;
  offset: number;
};

const listArgs = {
  id: props.blockData.ids,
  sectionId: [ESectionHandle.BRANDS],
  limit: 6,
  offset: 0,
};

const site = useSiteIdent();
const showMoreBtnId = 'show-more-btn-' + props.blockData.id;
const entries = ref(props.blockData.initalBrands);
const entryCount = ref(props.blockData.ids.length);
const showEntries = ref(false);
const isReloading = ref(false);

async function loadEntries(queryArgs: ExtendableListArgs) {
  try {
    const { data: fetchData } = await useCachedPost(
      `/api/${site}/content/list/extendable`,
      queryArgs,
    );

    if (entries.value.length) {
      entries.value.push(...fetchData.value);
    } else {
      entries.value = fetchData.value;
    }
  } catch (e) {
    error.value = true;
    handleLoadingError(e);
  }
}

async function toggleEntries() {
  if (isReloading.value) {
    return;
  }

  isReloading.value = true;
  if (entries.value.length < entryCount.value) {
    await loadEntries({
      id: listArgs.id,
      sectionId: listArgs.sectionId,
      limit: null,
      offset: listArgs.limit,
    });
  }

  showEntries.value = !showEntries.value;
  isReloading.value = false;

  if (!showEntries.value) {
    const lastElementId = 'list-entry-' + (listArgs.limit - 1);
    const element = document.getElementById(lastElementId);
    scrollToElement(element);
  }
}
</script>
